import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { Divider, Tooltip } from "antd";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Product from "../Home/Product/Product";
import NavbarCart from "../Navbar/NavbarCart";
import "./Cart.css";

import { FaMinus, FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import CopyRight from "../Footer/CopyRight";
import Footer from "../Footer/Footer";
import { noProductImg } from "../utilities/noProductImg";
import notMatchedIcon from "./not-matched.png";
import stockOutIcon from "./package-cancelled.svg";

const Cart = (props) => {
	const updateCart = props.updateCart;
	const categorys = props.categorys;
	const [showToastify, setShowToastify] = useState(false);
	const [crossSellProducts, setCrossSellProducts] = useState([]);
	const [upSellProducts, setUpSellProducts] = useState([]);

	const cartData = useSelector((state) => state.cart.data);
	const cartPrices = useSelector((state) => state.cart.prices);
	const selectedLocation = useSelector((state) => state.locationData.selectedLocation);

	useEffect(() => {
		if (showToastify) {
			toast.success("Product Added to Cart!", {
				position: "top-center",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
		setTimeout(() => setShowToastify(false), 1000);
	}, [showToastify]);

	useEffect(() => {
		let tempUpSell = [];
		let tempCrossSell = [];
		cartData.forEach((cartProduct) => {
			if (cartProduct.details.related_products) {
				tempUpSell = tempUpSell.concat(cartProduct.details.related_products.upsell);
				tempCrossSell = tempCrossSell.concat(cartProduct.details.related_products.crosssell);
			}
		});
		setCrossSellProducts(tempCrossSell);
		setUpSellProducts(tempUpSell);
	}, [cartData]);

	const handleProductVariation = (product) => {
		for (let variation of product.details.variations) {
			if (variation.id === product.variation_id) {
				return variation.attributes.map((attr) => attr.value).join(", ");
			}
		}

		return "";
	};

	const handleProductVariationImg = (product) => {
		for (let variation of product.details.variations) {
			if (variation.id === product.variation_id) {
				let img = variation.images[0];
				if (img) return img;
				break;
			}
		}

		return product.details.thumbnail;
	};

	const getTitle = (product) => {
		if (product.variation_id !== null) {
			for (let variation of product.details.variations) {
				if (variation.id === product.variation_id) {
					return variation.title;
				}
			}
		}

		return product.details.title;
	};

	const getSettings = (newProducts) => {
		return {
			slidesToShow: 11,
			slidesToScroll: 1,
			dots: false,
			draggable: true,
			swipeToSlide: true,
			infinite: newProducts.length >= 11,
			arrows: newProducts.length >= 11,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 10,
						infinite: newProducts.length >= 10,
						arrows: newProducts.length >= 10,
					},
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 9,
						infinite: newProducts.length >= 9,
						arrows: newProducts.length >= 9,
					},
				},
				{
					breakpoint: 425,
					settings: {
						slidesToShow: 5,
						infinite: newProducts.length >= 5,
						arrows: newProducts.length >= 5,
					},
				},
				{
					breakpoint: 375,
					settings: {
						slidesToShow: 4,
						infinite: newProducts.length >= 4,
						arrows: newProducts.length >= 4,
					},
				},
				{
					breakpoint: 320,
					settings: {
						slidesToShow: 4,
						infinite: newProducts.length >= 4,
						arrows: newProducts.length >= 4,
					},
				},
			],
		};
	};
	const handleStockAvailability = (product) => {
		let currentProduct = product.details;
		if (product.variation_id !== null) {
			for (let variation of product.details.variations) {
				if (variation.id === product.variation_id) {
					currentProduct = variation;
					break;
				}
			}
		}

		let availableItems;
		if (currentProduct.show_stock == 1) {
			let totalQtn = 0;
			currentProduct.inventory.stocks.forEach((i) => {
				totalQtn += +i.quantity;
			});
			availableItems = totalQtn - currentProduct.sold;
			if (availableItems > 0) {
				if (availableItems < product.quantity) return availableItems;
				else return "instock";
			} else {
				return "stockout";
			}
		} else {
			if (currentProduct.inventory.stock_status == 1) {
				return "instock";
			} else return "stockout";
		}
	};

	const [productAvailability, setProductAvailability] = useState(true);
	const [stockUnavailability, setStockUnavailability] = useState(false);
	const [itemExceeded, setItemExceeded] = useState(false);

	const showCartProductsWithAvailability = () => {
		let productsAvailable = cartData.map((c) => {
			let found = false;

			let stockStatus = handleStockAvailability(c);
			if (stockStatus !== "instock" && stockStatus !== "stockout" && !itemExceeded) setItemExceeded(true);
			else if (stockStatus === "stockout" && !stockUnavailability) setStockUnavailability(true);
			if (c.details.shipping.available_areas === null) found = true;
			else if (c.details.shipping.available_areas !== null) {
				if (c.details.shipping.available_areas.length === 0) {
					found = true;
				} else
					found = c.details.shipping.available_areas.find((area_id) => area_id === selectedLocation.id + "");
			}
			if (found) {
				return { ...c, available: true, stockStatus: stockStatus };
			} else {
				if (productAvailability) setProductAvailability(false);
				return { ...c, available: false, stockStatus: stockStatus };
			}
		});

		const getAddonsHtml = (addons) => ({
			__html: addons.length > 0 ? "Addons: " + addons.map((addon) => addon.title).join(", ") : "",
		});

		return (
			<>
				{productsAvailable.map((product, index) => (
					<div key={product.id} className="container mb-2">
						<div className="d-flex flex-row justify-content-between bd-highlight bg-white card-border pe-2 my-1">
							<div className="col-3 d-flex">
								{product.details.thumbnail ? (
									<img
										className="cart-img"
										src={
											product.variation_id !== null
												? handleProductVariationImg(product)
												: product.details.thumbnail
										}
										alt=""
										onError={(e) => (e.target.src = noProductImg())}
									/>
								) : (
									<img
										className="cart-img"
										src={noProductImg()}
										alt=""
										onError={(e) => (e.target.src = noProductImg())}
									/>
								)}
							</div>
							<div className="col-9 ps-1">
								<div className="row">
									<div className="col-8">
										<div className="d-flex align-items-center py-1">
											<div className="text-start cart-product-title ">{getTitle(product)}</div>
											<div className="ms-1 d-flex align-items-center">
												{!product.available && (
													<Tooltip
														title="This product is not available in this area"
														color={"red"}
														className="me-2"
													>
														<ExclamationCircleTwoTone
															style={{
																fontSize: "11px",
																cursor: "pointer",
															}}
															twoToneColor="#eb2f96"
														/>
													</Tooltip>
												)}
												{product.stockStatus === "stockout" && (
													<Tooltip
														title="Product is out of the stock"
														color={"red"}
														className="me-2"
													>
														<img
															src={stockOutIcon}
															alt=""
															style={{
																cursor: "pointer",
																width: "11px",
																height: "11px",
															}}
														/>
													</Tooltip>
												)}
												{product.stockStatus !== "instock" &&
													product.stockStatus !== "stockout" && (
														<Tooltip
															title={`Maximum of ${product.stockStatus} items available`}
															color={"red"}
														>
															<img
																src={notMatchedIcon}
																alt=""
																style={{
																	cursor: "pointer",
																	width: "15px",
																}}
															/>
														</Tooltip>
													)}
											</div>
										</div>
										{/* {product.details.custom_fields ? (
									<div>
										{product.details.custom_fields.map(
											(field, index) => (
												<p className="text-start text-muted ps-0 pb-0 product-pera mb-0 mt-0">
													{" "}
													<span className="fw-bold">
														{field.lable}{" "}
													</span>
													{field.value}
												</p>
											)
										)}
									</div>
								) : null} */}

										{product.variation_id !== null && (
											<div className="product-variations text-start my-1">
												{handleProductVariation(product)}
											</div>
										)}

										{product.addons && (
											<div
												className="text-start mb-1 opacity-50"
												dangerouslySetInnerHTML={getAddonsHtml(product.addons)}
											></div>
										)}

										<div className="bd-highlight d-flex flex-row quantity-ranger flex-grow-1 align-items-center">
											<div
												className="webview-btn custom-webview-btn"
												onClick={(e) => updateCart("sub", index)}
											>
												<FaMinus />
											</div>
											<p className="p-0 m-0 mx-2 cart-text-quantity">{product.quantity}</p>
											<div
												className="webview-btn custom-webview-btn"
												onClick={(e) => updateCart("add", index)}
											>
												<FaPlus />
											</div>
										</div>
									</div>

									<div className="col-4">
										<div
											className="d-flex justify-content-end mt-1 me-2"
											onClick={(e) => updateCart("delete", index)}
										>
											<i className="far fa-trash-alt delete-color"></i>
										</div>
										<div className="cart-product-price d-flex justify-content-end align-items-center me-2">
											<span>
												<span className="currency-logo">{"৳ "}</span>
												{(
													cartPrices.entries[index]?.total +
													cartPrices.entries[index]?.addons_total
												).toFixed(2)}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</>
		);
	};

	return (
		<>
			<div className="home-scroll">
				<div className="mb-5 conainer-fluid pb-2">
					<NavbarCart categorys={categorys} title="Cart"></NavbarCart>

					<div className={"card-body cart my-3" + (cartData.length > 0 ? " d-none" : " ")}>
						<div className="col-sm-12 empty-cart-cls text-center mt-0">
							{" "}
							<img
								src="https://cdn.iconscout.com/icon/free/png-256/shopping-cart-1761527-1498563.png"
								alt=""
								width="200"
								height="200"
								className="img-fluid mb-4 mr-3"
							/>
							<div className="m-3 cart-empty-text">Your Cart is Empty</div>
							<Link to="/">
								<button type="button" className="btn btn-primary btn-sm">
									Explore Product
								</button>
							</Link>
						</div>
					</div>

					<div className={"" + (cartData.length > 0 ? "" : " d-none")}>
						<div className="d-flex flex-row justify-content-between bd-highlight ps-2 pe-2 mt-3">
							<div className="p-2 pt-0 pb-0 bd-highlight">
								<p className="mb-2 text-cart">Your Cart</p>
							</div>
						</div>

						{showCartProductsWithAvailability()}

						<div className="text-small container">
							<div className="bg-pricing">
								<div className="container ps-3 mb-0 mt-3 text-small">
									<div className="d-flex flex-row justify-content-between bd-highlight">
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-subtotal-price text-black">Subtotal</p>
										</div>
										<div className="bd-highlight">
											<p className="p-0 m-0 me-1 cart-subtotal-price price-digit">
												<span className="currency-mini">{"৳ "}</span>
												<span>
													{(cartPrices.sub_total + cartPrices.addons_total).toFixed(2)}
												</span>
											</p>
										</div>
									</div>
								</div>
								<div className="container ps-3 mb-0">
									<div className="d-flex flex-row justify-content-between bd-highlight">
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-subtotal-price text-black">Tax </p>
										</div>
										<div className="bd-highlight">
											<p className="p-0 m-0 me-1 cart-subtotal-price price-digit">
												<span>+</span>
												<span className="currency-mini">{"৳ "}</span>
												<span>{cartPrices.tax_total.toFixed(2)}</span>
											</p>
										</div>
									</div>
								</div>
								<div className="container ps-3 mb-0">
									<div className="d-flex flex-row justify-content-between bd-highlight mb-2">
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-subtotal-price text-black">Shipping Charge</p>
										</div>
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-subtotal-price me-1 price-digit">
												<span>+</span>
												<span className="currency-mini">{"৳ "}</span>
												<span>{cartPrices.shipping_total.toFixed(2)}</span>
											</p>
										</div>
									</div>
								</div>
								<div className="divider-section">
									<Divider dashed className="my-3" />
								</div>
								<div className="container ps-3 mb-0">
									<div className="d-flex flex-row justify-content-between bd-highlight mb-2">
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-total-price text-black">Total Payable</p>
										</div>
										<div className="bd-highlight">
											<p className="p-0 m-0 cart-total-price me-1">
												<span className="currency-logo">{"৳ "}</span>
												<span>{cartPrices.grand_total.toFixed(2)}</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Similar Products section */}
					{upSellProducts.length > 0 && (
						<div>
							<div className="bd-highlight ps-2 pe-2 text-start mt-3 mb-2">
								<div className="p-2 pt-0 pb-0 bd-highlight">
									<p className="mb-2 text-cart">You May Like</p>
								</div>
							</div>
							<div className="text-center mx-2">
								<div className="pb-2">
									<div className="bg-grey pb-1">
										<div className="mx-3">
											<Slider {...getSettings(upSellProducts)}>
												{upSellProducts.map((product) => (
													<Product
														key={product.title + "_similar"}
														product={product}
														cardType="Similar Product"
														showToastify={showToastify}
														setShowToastify={setShowToastify}
													></Product>
												))}
											</Slider>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{crossSellProducts.length > 0 && (
						<div>
							<div className="bd-highlight ps-2 pe-2 text-start mb-2">
								<div className="p-2 pt-0 pb-0 bd-highlight">
									<p className="mb-2 text-cart">Suggested Products</p>
								</div>
							</div>
							<div className="text-center mx-2">
								<div className="pb-2">
									<div className="bg-grey pb-1">
										<div className="mx-3">
											<Slider {...getSettings(crossSellProducts)}>
												{crossSellProducts.map((product) => (
													<Product
														key={product.title + "_suggested"}
														product={product}
														cardType="Similar Product"
														showToastify={showToastify}
														setShowToastify={setShowToastify}
													></Product>
												))}
											</Slider>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<CopyRight className="mt-4 mb-4" />
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Cart;
