import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import ShowMoreText from "react-show-more-text";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { noProductImg } from "../../utilities/noProductImg";
import { API_ENDPOINT_ITEM_VIEWED, parameterArray, parameters } from "../../utilities/Webview";
import { addCartProduct } from "../../actions/CartActions";
import Attributes from "./Attributes";
import CustomFields from "./CustomFields";
import "./Product.css";
import ProductAddons from "./ProductAddons";
import ProductCard from "./ProductCard";
import SimilarProducts from "./SimilarProducts/SimilarProducts";

const Product = (props) => {
	const [show, setShow] = useState(false);
	const { id, thumbnail, custom_fields, v_attributes, variations, addons } = props.product;
	const [title, setTitle] = useState(props.product.title);
	const [description, setDescription] = useState(props.product.description);
	const [vDescription, setVDescription] = useState(null);
	const [images, setImages] = useState(props.product.images);
	const [regularPrice, setRegularPrice] = useState(props.product.regular_price);
	const [wholesalePricing, setWholesalePricing] = useState(props.product.wholesale_pricing);
	const [variationId, setVariationId] = useState(null);
	const inputField = custom_fields;
	const [attributes, setAttributes] = useState([]);
	const [attributesValidity, setAttributesValidity] = useState(true);
	const [selectedAttributes, setSelectedAttributes] = useState([]);
	const [selectedAttributeValues, setSelectedAttributeValues] = useState([]);
	const [customFieldValues, setCustomFieldValues] = useState([]);
	const [customFieldsValidity, setCustomFieldsValidity] = useState(true);
	const [photoSlider, setPhotoSlider] = useState([]);
	const setShowToastify = props.setShowToastify;
	const [updateAttributeState, setUpdateAttributeState] = useState(1);
	const [stock, setStock] = useState({
		inventory: props.product.inventory,
		show_stock: props.product.show_stock,
		sold: props.product.sold,
	});
	const [stockStatus, setStockStatus] = useState("In Stock");
	const [remainingItems, setRemainingItems] = useState(0);
	const handleModalShow = () => {
		setShow(true);
	};

	const [price, setPrice] = useState(Number(props.product.price));
	const [addonPrice, setAddonPrice] = useState(0);

	const [qtnNumber, setQtnNumber] = useState(1);
	const [totalPrice, setTotalPrice] = useState(price + addonPrice);

	const dispatch = useDispatch();

	const setVariation = (valid, selectedVariation = null, selectedVariationId = null) => {
		if (!valid) {
			setTitle(props.product.title);
			setStock({
				inventory: props.product.inventory,
				show_stock: props.product.show_stock,
				sold: props.product.sold,
			});
			setDescription(props.product.description);
			setVDescription(null);
			setImages(props.product.images);
			setPrice(Number(props.product.price));
			setRegularPrice(props.product.regular_price);
			setWholesalePricing(props.product.wholesale_pricing);
		} else if (selectedVariation && selectedVariationId !== null) {
			setVariationId(selectedVariationId);
			setTitle(props.product.title + " - " + selectedVariation.title);
			setStock({
				inventory: selectedVariation.inventory,
				show_stock: selectedVariation.show_stock,
				sold: selectedVariation.sold,
			});
			setVDescription(
				selectedVariation.description && selectedVariation.description.length > 0
					? selectedVariation.description
					: null
			);
			if (selectedVariation.images.length > 0) {
				setImages(selectedVariation.images);
			}
			setPrice(Number(selectedVariation.price));
			setRegularPrice(selectedVariation.regular_price);
			setWholesalePricing(selectedVariation.wholesale_pricing);
		}
	};

	const validateAttributes = (id, value) => {
		var index = selectedAttributes.indexOf(id);
		if (index === -1) {
			selectedAttributes.push(id);
			selectedAttributeValues.push(value);
		} else {
			selectedAttributeValues[index] = value;
		}

		var matchedVariations = variations;
		var matchedVariationId = null;
		matchedVariations = matchedVariations.filter((variation, index4) => {
			let match = true;

			selectedAttributes.map((selectedAttribute, index3) => {
				if (match) {
					match =
						variation.attributes.filter((v_attribute, index5) => {
							return (
								v_attribute.id === selectedAttribute &&
								v_attribute.value === selectedAttributeValues[index3]
							);
						}).length > 0;
				}

				return selectedAttribute;
			});

			if (match && !matchedVariationId) matchedVariationId = variation.id;
			return match;
		});

		setSelectedAttributes(selectedAttributes);
		setSelectedAttributeValues(selectedAttributeValues);
		setVariation(
			matchedVariations.length > 0,
			matchedVariations.length > 0 ? matchedVariations[0] : null,
			matchedVariationId
		);
		setAttributesValidity(matchedVariations.length > 0);

		setUpdateAttributeState(updateAttributeState + 1);
	};

	useEffect(() => {
		let attrs = [];
		v_attributes.map((value, index) => {
			var values = [];
			value.values.map((value2, index2) => {
				values.push({
					enabled: true,
					value: value2,
				});

				return value;
			});
			var attribute = {
				id: value.id,
				details: value.details,
				values: values,
			};
			attrs.push(attribute);
			validateAttributes(value.id, values[0].value);

			return value;
		});

		setAttributes(attrs);
	}, [v_attributes]);

	useEffect(() => {
		setCustomFieldsValidity(
			custom_fields.filter((field, index) => {
				return field.required;
			}).length === 0
		);
	}, [custom_fields]);

	useEffect(() => {
		if (show) {
			var params = parameterArray(false);
			if (params.productId && Number(params.productId) === id) {
				window.history.replaceState(
					{},
					document.title,
					window.location.href.replace("&productId=" + params.productId, "")
				);
			}

			var data = { item_id: id };
			axios
				.post(`${API_ENDPOINT_ITEM_VIEWED}?${parameters()}`, data)
				.then((res) => {})
				.catch((err) => console.log(err));
		}
	}, [show]);

	useEffect(() => {
		var params = parameterArray(false);
		if (params.productId && Number(params.productId) === id) {
			setShow(true);
		}
	}, [id]);

	useEffect(() => {
		let photoSliders = [];
		if (!variationId) {
			photoSliders.push({
				original: thumbnail ?? noProductImg(),
				thumbnail: thumbnail ?? noProductImg(),
			});
		}

		images.forEach(function (picture, index) {
			var photo = {
				original: picture ?? noProductImg(),
				thumbnail: picture ?? noProductImg(),
			};
			photoSliders.push(photo);
		});
		setPhotoSlider(photoSliders);
	}, [images]);

	const cartAddons = useSelector((state) => state.addons);
	const checkIfRequiredAddonsSelected = (cartAddons) => {
		let isValid = true;
		const allGroupIds = Object.keys(cartAddons.groupAddons);
		const groups = cartAddons.groups;
		if (groups.length) {
			if (allGroupIds.length >= groups.length) {
				for (let index = 0; index < groups.length; index++) {
					if (!allGroupIds.includes(groups[index])) {
						isValid = false;
						break;
					}
				}
			} else isValid = false;
		}
		return isValid;
	};
	function handleClose() {
		if (checkIfRequiredAddonsSelected(cartAddons)) {
			dispatch(
				addCartProduct({
					product: props.product,
					quantity: qtnNumber,
					cfValues: customFieldValues.map((value, index) => {
						return {
							id: value.id,
							value: value.value,
						};
					}),
					variationId: variationId,
					addons: Object.values(cartAddons.groupAddons).flat(1),
				})
			);
			setShow(false);
			if (props.cardType !== "Product") setShowToastify(true);
			else
				toast.success("Product successfully added to cart!", {
					position: "top-center",
					autoClose: 1000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
		} else {
			toast.error("You must select the required addons!", {
				position: "top-center",
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	}

	useEffect(() => {
		var total = 0;
		if (cartAddons) {
			Object.values(cartAddons.groupAddons)
				.flat(1)
				.forEach((item) => {
					total += Number(item.price);
				});
		}
		setAddonPrice(total);
	}, [cartAddons]);

	const plusQtn = () => {
		setQtnNumber(qtnNumber + 1);
	};

	const minusQtn = (e) => {
		if (qtnNumber > 1) {
			setQtnNumber(qtnNumber - 1);
		}
	};

	useEffect(() => {
		let newPrice = qtnNumber * (price + addonPrice);
		wholesalePricing.forEach((pricing, index2) => {
			if (Number(pricing.quantity) <= qtnNumber && Number(pricing.wholesale_price) <= price + addonPrice) {
				newPrice = (Number(pricing.wholesale_price) + Number(addonPrice)) * qtnNumber;
			}
		});

		setTotalPrice(newPrice);
	}, [qtnNumber, price, addonPrice]);

	const validateCustomFields = () => {
		var valid = true;
		customFieldValues.map((field, index) => {
			if (field.value === "") valid = false;
			return field;
		});

		return setCustomFieldsValidity(valid);
	};

	const handleStock = () => {
		let availableItems;
		if (stock.show_stock == 1) {
			let totalQtn = 0;
			stock.inventory.stocks.forEach((i) => {
				totalQtn += +i.quantity;
			});
			availableItems = totalQtn - stock.sold;
			if (availableItems > 0) {
				setStockStatus(1);
			} else {
				setStockStatus(0);
			}
			if (availableItems <= 0) availableItems = 0;
		} else {
			if (stock.inventory.stock_status == 1) {
				setStockStatus(1);
			} else {
				setStockStatus(0);
			}
			availableItems = -1;
		}
		setRemainingItems(availableItems);
	};

	useEffect(() => {
		handleStock();
	}, [stock]);

	return (
		<div className="d-flex justify-content-between pb-2" onClick={handleModalShow}>
			<>
				{props.cardType === "Product" ? (
					<ProductCard
						product={[
							props.product.thumbnail,
							props.product.title,
							props.product.summary,
							props.product.price,
							props.product.regular_price,
						]}
					></ProductCard>
				) : (
					<SimilarProducts product={[props.product.thumbnail, props.product.price]}></SimilarProducts>
				)}

				{/* Product details show using modal */}
				<div onClick={(e) => e.stopPropagation()}>
					<Modal
						id="modal-product"
						className="modal-dialog-centered modal-p-bottom justify-content-center mx-auto align-items-center"
						show={show}
						onHide={() => setShow(false)}
						dialogClassName=""
						aria-labelledby="product-modal"
					>
						<Modal.Header closeButton className="modalDetails bg-color-default"></Modal.Header>
						<Modal.Body>
							<div className="modal-body-scroll">
								<div className="mb-3">
									{photoSlider.length ? (
										<ImageGallery
											showPlayButton={false}
											showFullscreenButton={false}
											thumbnailWidth="20px"
											items={photoSlider}
											onError={(e) => (e.target.src = noProductImg())}
										/>
									) : (
										<img
											src={noProductImg()}
											className="img-fluid ms-0 product-img"
											alt={title}
											onError={(e) => (e.target.src = noProductImg())}
										/>
									)}
								</div>
								<div className="row ps-4 pe-4">
									<div className="col-9">
										<div className="title-modal">{title}</div>
									</div>
									<div className="col-3 text-end ps-0">
										{regularPrice > price && (
											<div className="discount-price">
												<del>
													<span className="cart-currency-logo">{"৳ "}</span>
													{price}
												</del>
											</div>
										)}
										<small className="product-price price-modal">
											<span className="cart-currency-logo">{"৳ "}</span>
											{price}
										</small>
									</div>
								</div>
								<div className="ps-4 d-flex align-items-center">
									{stockStatus == 1 ? (
										<>
											<CheckCircleTwoTone twoToneColor="#52c41a" />
											<div className="stock-status ms-1 text-success">In Stock</div>
										</>
									) : (
										<>
											<CloseCircleTwoTone twoToneColor="#eb2f96" />
											<div className="stock-status ms-1 text-danger">Out of Stock</div>
										</>
									)}
								</div>
								<div className="d-flex flex-column justify-content-center modal-box h-100 w-100 pt-1">
									<ShowMoreText
										/* Default options */
										lines={1}
										less="Show less"
										className="ps-4 pe-3 text-justify description-modal"
										more={<div className="mt-2">Show More ...</div>}
										// onClick={this.executeOnClick}
										expanded={false}
										truncatedEndingComponent={"... "}
									>
										<div
											dangerouslySetInnerHTML={{
												__html: `${description ?? ""}${
													vDescription ? "<br>" + vDescription : ""
												}`,
											}}
										></div>
									</ShowMoreText>

									{/* <div className="row  ps-4 mt-2">
                    <div className="col-2 attribute-title">Price</div>
                    <div className="col-9 price-text d-flex align-items-center">
                      <div className="text-center fw-bold product-price mb-0 d-flex align-items-center">
                        {regularPrice > price && (
                          <div className="discount-price me-3">
                            <del>
                              <span className="cart-currency-logo">{"৳ "}</span>
                              {price}
                            </del>
                          </div>
                        )}
                        <small className="product-price price-modal">
                          <span className="cart-currency-logo">{"৳ "}</span>
                          {price}
                        </small>
                      </div>
                    </div>
                  </div> */}

									{/* <div className="ps-4 mt-1">
										<small className="attribute-title">
											Quantity
										</small>
										<span id="cart-qtn" className="ms-2">
											{qtnNumber}
										</span>
										<small
											className={
												remainingItems >= 0
													? "ms-2 text-muted"
													: "d-none"
											}
										>
											{remainingItems === 0
												? "No "
												: `Only ${remainingItems} `}
											items left
										</small>
									</div> */}
									<div className="ps-4 mt-1">
										<ProductAddons addons={addons} />
									</div>
									{updateAttributeState &&
										attributes.map((attribute, index) => (
											<Attributes
												validateAttributes={validateAttributes}
												attribute={attribute}
												selectedAttributes={selectedAttributes}
												selectedAttributeValues={selectedAttributeValues}
											></Attributes>
										))}

									<div
										className={
											attributesValidity
												? "text-small ps-4 text-danger d-none"
												: "text-small ps-4 text-danger"
										}
									>
										Sorry, selected variation is not available
									</div>

									<div className="pb-5 pt-1">
										{inputField.map((iField, index) => (
											<CustomFields
												key={iField.id}
												iField={iField}
												customFieldValues={customFieldValues}
												setCustomFieldValues={setCustomFieldValues}
												validateCustomFields={validateCustomFields}
											></CustomFields>
										))}
									</div>
								</div>
							</div>
						</Modal.Body>

						{/* Footer */}
						<Modal.Footer className="d-flex justify-content-around">
							<div className="d-flex py-3 px-2  justify-content-between align-items-center div-footer">
								<div className="d-flex flex-column align-items-center">
									<div className="d-flex flex-row btn-qtn">
										<div className="bd-highlight">
											<div
												className="px-2 btn-square webview-btn"
												id="minus-qtn"
												onClick={minusQtn}
											>
												<i className="fas fa-minus fa-sm"></i>
											</div>
										</div>

										<div className="bd-highlight">
											<div className="ps-2 pe-2" id="qtn">
												{qtnNumber}
											</div>
										</div>

										<div className="bd-highlight">
											<div
												className="px-2 btn-square webview-btn"
												id="plus-qtn"
												onClick={plusQtn}
											>
												<i className="fas fa-plus fa-sm"></i>
											</div>
										</div>
									</div>
									<small className={remainingItems >= 0 ? "text-muted" : "d-none"}>
										{remainingItems === 0 ? "No " : `Only ${remainingItems} `}
										items left
									</small>
								</div>

								<div className="fw-bold text-blue d-flex align-items-center justify-content-center">
									<span id="updatedPrice">
										<span className="cart-currency-logo fw-bold">{"৳ "}</span>
										<span className="modal-currency fw-bold" id="modal-price">
											{totalPrice}
										</span>
									</span>
								</div>

								<button
									className="btn btn-footer text-small webview-btn fw-600"
									onClick={handleClose}
									disabled={!attributesValidity || !customFieldsValidity}
								>
									Add to Cart
								</button>
							</div>
						</Modal.Footer>
					</Modal>
				</div>
			</>
		</div>
	);
};

export default Product;
