const handleProductPrice = (product) => {
	let newPrice = product.details.price;
	let productPrice = parseFloat(newPrice);
	if (product.variation_id !== null) {
		let variation = null;
		for (let variation_temp of product.details.variations) {
			if (variation.id === product.variation_id) {
				variation = variation_temp;
				break;
			}
		}

		productPrice = parseFloat(variation.price);
		newPrice = productPrice;
		variation.wholesale_pricing.forEach((pricing, index2) => {
			if (Number(pricing.quantity) <= product.quantity && Number(pricing.wholesale_price) <= productPrice) {
				newPrice = Number(pricing.wholesale_price);
			}
		});
	}
	return newPrice;
};

export { handleProductPrice };
